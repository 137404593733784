import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/reusables/Layout"
import SEO from "../components/reusables/seo"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import addLineBreaks from "../helpers/addLineBreaks"
import styled from "styled-components"
import tw from "twin.macro"
import ContactForm from "../components/reusables/ContactForm"

const RichTextWrapper = styled.div`
  p {
    ${tw`text-center mt-8`}
  }
`

export default function ContactPageTemplate({ data }) {
  const page = data.contentfulContactPage
  const richTextOptions = {
    renderText: addLineBreaks,
  }
  return (
    <>
      <SEO title={data.contentfulContactPage.title} />
      <Layout>
        <section id="main" className="max-w-md mx-auto font-light">
          <h1 className="text-center mt-36 text-4xl text-primary">
            {page.title}
          </h1>
          <RichTextWrapper>
            {renderRichText(page.description, richTextOptions)}
          </RichTextWrapper>
          <ContactForm />
        </section>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulContactPage(slug: { eq: $slug }) {
      node_locale
      title
      description {
        raw
      }
    }
  }
`
